import * as React from "react"
import Layout from "../components/layout"
import { Link } from 'gatsby'
import "@fontsource/raleway/300.css"
import "@fontsource/raleway/500.css"
import styled from 'styled-components'
import Hero from '../components/hero'
import { Helmet } from "react-helmet"

// styles
const MainBodySection = styled.article`
  max-width: 960px;
  margin: 0 auto 50px;
}
`;

const SiteMapList = styled.ul`
  list-style: none;
  column-count: 2;
  column-gap: 20px;

  @media only screen and (max-width: 540px) {
    column-count: 1;
  }

  > li {
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;

    > a {
      border-bottom: 1px dotted #c9b47d; 
      font-size: 16px;    
    }
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 5px;
    }

    a {
      border-bottom: 1px dotted #c9b47d; 
      font-size: 14px;
      font-weight: 500;    
    }
  }
`;

// markup
const SitemapPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Site Map | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/sitemap" />
          <meta name="description" content="The Opera 101 Site Map detailing all the different pages that exist within the site." />
        </Helmet>
        <Hero title="Site Map"/>
        <MainBodySection>
          <SiteMapList>
            <li><Link to='/'>Home</Link></li>
            <li><Link to='/operaabc'>Opera ABCs</Link>
                <ul>
                    <li><Link to='/operaabc/arias'>Arias</Link></li>
                    <li><Link to='/operaabc/history/english'>English Opera</Link></li>
                    <li><Link to='/operaabc/gettingstarted'>Getting Started</Link></li>
                    <li><Link to='/operaabc/glossary'>Glossary</Link></li>
                    <li><Link to='/operaabc/history'>History</Link></li>
                    <li><Link to='/operaabc/voices'>Opera Voices</Link></li>
                    <li><Link to='/operaabc/runningtimes'>Running Times</Link></li>
                </ul>
            </li>
            <li><Link to='/composers'>Composers</Link>
                <ul>
                    <li><Link to='/composers/puccini'>Puccini</Link></li>
                    <li><Link to='/composers/verdi'>Verdi</Link></li>
                </ul>
            </li>
            <li><Link to='/operas'>Operas</Link>
                <ul>
                    <li><Link to='/operas/boheme'>La bohème</Link></li>
                    <li><Link to='/operas/carmen'>Carmen</Link></li>
                    <li><Link to='/operas/cavalleria'>Cavalleria rusticana</Link></li>
                    <li><Link to='/operas/giovanni'>Don Giovanni</Link></li>
                    <li><Link to='/operas/onegin'>Eugene Onegin</Link></li>
                    <li><Link to='/operas/butterfly'>Madama Butterfly</Link></li>
                    <li><Link to='/operas/flute'>The Magic Flute</Link></li>
                    <li><Link to='/operas/dream'>A Midsummer Night's Dream</Link></li>
                    <li><Link to='/operas/grimes'>Peter Grimes</Link></li>
                    <li><Link to='/operas/rigoletto'>Rigoletto</Link></li>
                    <li><Link to='/operas/barbiere'>The Barber of Seville</Link></li>
                    <li><Link to='/operas/figaro'>The Marriage of Figaro</Link></li>
                    <li><Link to='/operas/screw'>The Turn of the Screw</Link></li>
                    <li><Link to='/operas/tosca'>Tosca</Link></li>
                    <li><Link to='/operas/traviata'>La Traviata</Link></li>
                    <li><Link to='/operas/turandot'>Turandot</Link></li>
                </ul>
            </li>
            <li><Link to='/experience'>Experience</Link>
                <ul>
                    <li><Link to='/operahouses/bayreuth'>Bayreuth</Link></li>
                    <li><Link to='/operahouses/royalopera'>Royal Opera House</Link></li>
                    <li><Link to='/operahouses/metopera'>Metropolitan Opera</Link></li>
                    <li><Link to='/operahouses/glyndebourne'>Glyndebourne</Link></li>
                </ul>
            </li>
        </SiteMapList>
        </MainBodySection>
    </Layout>
  )
}

export default SitemapPage
